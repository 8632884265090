.pnl-noprofit{
    background-color: rgba(255,193,7,.3)!important;
}

.pnl-win{
    background-color: rgba(25,135,84,.3)!important;
}

.pnl-loss{
    background-color: rgba(220,53,69,.3)!important;
}